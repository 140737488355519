import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import __DEPRECATED_defineResources from 'dpl/shared/fetching/deprecated/defineResources';
import { newsletterSignupResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import { CONSTRAINT_TYPES } from 'dpl/shared/validations/constraints';
import withFormCapabilities from 'dpl/form/decorators/withFormCapabilities';
import NewsletterForm from 'dpl/components/NewsletterForm';
import useBreederSearchUpdatesExp from 'dpl/experiments/exp_breeder_search_updates/breeder_profile/hooks/useBreederSearchUpdatesExp';

function NewsletterFormContainer(props) {
  const { isExperiment } = useBreederSearchUpdatesExp();

  if (props.pageName === 'breeder_profiles_show' && isExperiment) {
    return null;
  }

  return <NewsletterForm {...props} />;
}

NewsletterFormContainer.propTypes = {
  pageName: PropTypes.string
};

NewsletterFormContainer.defaultProps = {
  pageName: null
};

export default compose(
  __DEPRECATED_defineResources(() => ({
    newsletterData: newsletterSignupResourceDefinition()
  })),
  withFormCapabilities(() => ({}), {
    mapPropsAndStateToValidationSchema: (_, { email }) => ({
      email: email ? CONSTRAINT_TYPES.EMAIL : CONSTRAINT_TYPES.TEXT
    }),
    onSubmit: (props, formStateForSubmission) =>
      props.newsletterData.submit({
        user: formStateForSubmission
      })
  }),
  connect(({ server }) => ({
    pageName: server.pageName || ''
  }))
)(NewsletterFormContainer);
